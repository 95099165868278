<template>
  <v-select v-model="record[property]"
            :items="items"
            :label="title || $options.filters.titleize(type)"
            :class="classes.join(' ')"
            :multiple="multiple"
            :prepend-inner-icon="icon()"
            :item-text="toName"
            item-value="id"
            :clearable="clearable"
            :outline="outline"
            :filled="filled"
            :solo="solo"
            :return-object="returnObject"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="record[property].length === 1">{{ item.name }}</span>
      <span v-if="record[property].length > 1 && index === 0">{{ record[property].length }} {{ type | humanize }}</span>
    </template>
  </v-select>
</template>

<script>
import { Record } from 'vuex-jsonapi'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    record: {
      type: [Object, Record],
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true
    },
    returnObject: Boolean,
    outline: Boolean,
    filled: Boolean,
    solo: Boolean,
  },
  computed: {
    items() {
      return this.$store.getters[this.type];
    },
  },
  methods: {
    toName(item) {
      let name = item.name;
      if (['locations'].indexOf(this.type) > -1) name += ` (${item.id})`;
      return name;
    },
    icon() {
      return {
        grades: 'people',
        locations: 'language',
      }[this.type];
    }
  }
}
</script>
