<template>
  <div class="list-channel-helper">

    <!--loading-->
    <busy-mask :color="color" v-if="loading"></busy-mask>
    <v-list-item v-if="!records || (records.length < 1 && loading)"></v-list-item>

    <!--not found-->
    <v-list-item v-if="noRecords">
      <v-list-item-content>
        <v-list-item-title>{{ noRecordsText }}</v-list-item-title>
        <v-list-item-sub-title v-if="noRecordsSubText">{{ noRecordsSubText }}</v-list-item-sub-title>
      </v-list-item-content>
    </v-list-item>

    <!--error-->
    <v-list-item v-if="error">
      <v-list-item-content>
        <v-list-item-title>{{ errorText }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import BusyMask from './busy-mask'

export default {
  components: { BusyMask },
  props: {
    channel: {
      required: true,
      type: String,
    },
    color: {
      type: String,
      default: 'primary',
    },
    noRecordsText: {
      type: String,
      default: 'No records found',
    },
    noRecordsSubText: String,
    errorText: {
      type: String,
      default: 'Something went wrong :-(',
    }
  },
  mounted() {
    console.log(this.$parent.$el);
    this.$parent.$el.classList.add('has-list-channel-helper');
  },
  computed: {
    records() { return this.$store.getters.channel(this.channel) },
    loading() { return this.$store.getters.loading(this.channel) },
    noRecords() { return this.$store.getters.noRecords(this.channel) },
    error() { return this.$store.getters.error(this.channel) },
  },
}
</script>

<style lang="scss">
  .has-list-channel-helper {
    position: relative !important;
  }
</style>
