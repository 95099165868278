<template>
  <v-app>
    <router v-if="currentUser"></router>
    <busy-mask v-else-if="loading"></busy-mask>
    <login v-else></login>
    <v-snackbar :timeout="4000" bottom left v-model="snackbar">
      {{ snackbarMessage }}
      <v-btn text @click.native="snackbar = null">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import * as Sentry from '@sentry/browser'

import BusyMask from '../components/busy-mask'
import Login from './login'
import Router from './layout'

export default {
  components: { Login, Router, BusyMask },
  data: () => ({
    loading: false,
  }),
  mounted () {
    // this.loading = true;
    this.$api
      .requestRecords('get', 'me')
      .then(({ data, included }) => {
        let user = data;

        this.$store.dispatch('updateCurrentUser', user);
        Sentry.setUser({ id: user.id });
        if (this.$ga) {
          this.$ga.set('userId', user.id);
        }
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        this.$store.dispatch('updateSnackbar', error.response && error.response.status && error.response.status === 401 ? 'Please login to continue' : 'There was an error checking your login status');
      });
  },
  computed: {
    snackbar: {
      get () { return !!this.$store.getters.snackbarMessage; },
      set () { this.$store.dispatch('updateSnackbar', null); }
    },
    ...mapGetters([
      'snackbarMessage',
      'currentUser',
    ])
  },
}
</script>

<style lang="scss">
  @import "../../../node_modules/vuetify/dist/vuetify.min";
  @import './app/assets/stylesheets/_variables';

  // Typography
  .text-muted {
    color: #666;
  }

  // Highlighted bold
  strong.highlight {
    color: #ed2a7b !important;
  }

  // Animations
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .15s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;

    &.slide-fade-enter {
      transform: translateY(-10px);
    }
    &.slide-fade-leave-to {
      transform: translateY(10px);
    }
  }

  // State classes
  .state {
    font-weight: 500;
  }

  // State backgrounds for different models
  .state-background {
    color: white;
  }

  // Chips should have a little shadow
  .v-chip {
    box-shadow: 0 1px 2px rgba(black, 0.2);

    // Background colors should be inverted
    &.state-background {
      font-weight: 500;
      color: white;
    }
  }

  // Dialogs should be light gray
  .v-dialog {
    background: #f8f8f8;

    .v-card {
      background: transparent;

      // ...but actions should be white
      .v-card__actions {
        background: white;
      }
    }

    .input-group label {
      background: #f8f8f8;
    }
  }

  // Layout outlines debug class
  .debug-layout {
    .container { border: 1px solid red; }
    .layout { border: 1px solid green; }
    .flex { border: 1px solid blue; }
  }

  // Override for weird behavior using containers in components with borders
  @media only screen and (min-width: 960px) {
    .container {
      max-width: 10000px;
    }
  }

  // Data tables should have left-aligned custom actions
  .v-datatable__actions {
    position: relative !important;

    .custom-actions {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
</style>
