import VueRouter from 'vue-router'

import Customers from './routes/customers'
import Customer from './routes/customer'
import Inventory from './routes/inventory'
import InventoryStages from './routes/inventory-stages'
import Products from './routes/products'
import Product from './routes/product'

import NotFound from './main/not-found'

const router = [
  // Home redirect
  {
    path: '/',
    redirect: '/products',
  },
  // Main routes
  {
    name: 'customers',
    path: '/customers',
    component: Customers,
  },
    {
      name: 'customer',
      path: '/customers/:customerId',
      component: Customer,
      props: true,
    },
  {
    name: 'products',
    path: '/products',
    component: Products,
  },
    {
      name: 'product',
      path: '/products/:productId',
      component: Product,
      props: true,
    },
  {
    name: 'inventory',
    path: '/inventory',
    component: Inventory,
  },
  {
    name: 'inventory-stages',
    path: '/inventory-stages',
    component: InventoryStages,
  },

  // 404
  {
    name: 'not_found',
    path: '*',
    component: NotFound,
  }
];

export default new VueRouter({
  routes: router,
  mode: 'history'
});
