// Default Rails
// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import 'core-js'

import Vue from 'vue'
import Vuetify from 'vuetify'
import VueAnalytics from 'vue-analytics'
import VueClipboard from 'vue-clipboard2'
import VueRouter from 'vue-router'
import VueTilities from 'vue-tilities'
import sanitizeHtml from 'sanitize-html'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

// Set up Sentry.io error tracking
if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.RELEASE_SHA,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

// Set up Google Analytics tracking
if (process.env.GOOGLE_ANALYTICS_ID) {
  Vue.use(VueAnalytics, {
    id: process.env.GOOGLE_ANALYTICS_ID
  });
  router.afterEach((to) => {
    Vue.$ga.page(to.path);
  });
}

import store from '../store'
import router from '../router'
import stringUtils from '../utilities/string-utils'
import momentUtils from '../utilities/moment-utils'
import Filters from '../utilities/filters'

import App from '../main/app'

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);


Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(VueTilities, {
  stringUtils: stringUtils(),
  momentUtils: momentUtils(),
});
Vue.use(Filters);

Vue.prototype.$sanitize = sanitizeHtml;

// Instantiate Vuetify
let vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: Object.assign({}, colors.teal, {
        primary: '#ed2a7b',
        accent: '#ed2a7b',

        // Contextual
        error: '#fa6b5b',
        info: '#00c0ff',
        success: '#34c9a9',
        warning: '#ffc900',
      }),
      dark: Object.assign({}, colors.teal, {
        primary: '#ed2a7b',
      }),
    }
  }
});

document.addEventListener('DOMContentLoaded', () => {
  document.body.appendChild(document.createElement('app'));
  new Vue({
    vuetify,
    store,
    router,
    el: 'app',
    components: { App },
    render: h => h(App),
  });
});
