<template>
  <v-btn :dark="dark"
         :text="text"
         :color="model.dirty() ? color : null"
         @click="model.dirty() ? emit('save') : emit('close')"
         :disabled="disabled"
  >
    {{ model.dirty() ? (model._persisted ? 'Update' : 'Create') : 'Close' }}
  </v-btn>
</template>

<script>
import { Record } from 'vuex-jsonapi'

export default {
  props: {
    model: {
      required: true,
      type: Record,
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: Boolean,
    text: Boolean,
    dark: Boolean,
  },
  methods: {
    emit(event) { this.$emit(event); },
  }
}
</script>
