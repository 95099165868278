<template>
  <v-layout
    fill-height
    :pa-4="$vuetify.breakpoint.smAndUp"
    :pa-3="$vuetify.breakpoint.xsOnly"
    column
    justify-start
  >
    <v-flex xs12>
      <h1 class="display-1">Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => {
    return {};
  }
}
</script>

<style scoped lang="scss">
</style>
