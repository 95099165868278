<template>
  <v-layout
    fill-height
    :pa-4="$vuetify.breakpoint.smAndUp"
    :pa-3="$vuetify.breakpoint.xsOnly"
    column
    justify-start
  >
    <v-flex xs12>
      <h1 class="display-1 mb-3">
        <span v-if="model">{{ model.name }}</span>
        <span v-else>Loading...</span>
      </h1>

      <!--inventory-->
      <div class="headline mb-2">Inventory Quantities</div>
      <inventory-levels :product="model"
                        v-if="model"
                        @reload="load"
                        class="mb-4"
      ></inventory-levels>

      <h2 class="headline mb-2">Product Details</h2>
      <template v-for="attribute in attributes">
        <div class="title">{{ attribute.label }}</div>
        <div class="subtitle-1" v-if="loading">Loading...</div>
        <div class="subtitle-1" v-else-if="model && model[attribute.name]">
          <template v-if="attribute.type === 'currency'">{{ model[attribute.name] | currency }}</template>
          <template v-else-if="attribute.type === 'humanize'">{{ model[attribute.name] | titleize }}</template>
          <template v-else-if="attribute.type === 'weight'">{{ model[attribute.name] | numeral('0,0[.]00') }} lbs.</template>
          <template v-else-if="attribute.type === 'number'">{{ model[attribute.name] | numeral('0,0[.]00') }}</template>
          <div v-else-if="attribute.type === 'html'" v-html="model[attribute.name]"></div>
          <template v-else>{{ model[attribute.name] }}</template>
        </div>
        <em class="subtitle-1" v-else>Unspecified</em>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapChannel } from 'vuex-jsonapi';

import InventoryLevels from '../components/inventory-levels'

export default {
  components: { InventoryLevels },
  props: ['productId'],
  data: () => ({
    attributes: [
      { name: 'category', label: 'Category', type: 'humanize' },
      { name: 'name', label: 'Name' },
      { name: 'price', label: 'Price', type: 'currency' },
      { name: 'weight', label: 'Weight', type: 'weight' },
      { name: 'description', label: 'Description', type: 'html' },
    ]
  }),
  beforeMount() {
    this.load();
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapChannel('productProduct'),

  },
  methods: {
    load() {
      this.$store.dispatch('find', {
        channel: 'productProduct',
        type: 'products',
        id: this.productId,
        params: {
          include: 'inventory_levels,inventory_levels.stage',
        }
      })
    },
  }
}
</script>
