<template>
  <v-layout
    fill-height
    :pa-4="$vuetify.breakpoint.smAndUp"
    :pa-3="$vuetify.breakpoint.xsOnly"
    column
    justify-start
  >
    <v-flex xs12>
      <h1 class="display-1 mb-3">
        Customers
        <span v-if="customer"> &gt; {{ customer.full_name }}</span>
      </h1>

      <v-layout wrap>
        <v-flex xs12 md6>
          <h2 class="headline mb-2">Customer Details</h2>
          <template v-for="attribute in attributes">
            <div class="title">{{ attribute.label }}</div>
            <div class="subtitle-1" v-if="!customer">Loading...</div>
            <div class="subtitle-1" v-else-if="customer && customer[attribute.name]">{{ customer[attribute.name] }}</div>
            <em class="subtitle-1" v-else>Unspecified</em>
          </template>
        </v-flex>

        <v-flex xs12 md6 v-if="customer">
          <h2 class="headline mb-2">Primary Addresses</h2>
          <v-list class="elevation-1 mb-3">
            <address-list-item label="Billing Address" :model="customer.billing_address" v-if="customer.billing_address"></address-list-item>
            <address-list-item label="Shipping Address" :model="customer.shipping_address" v-if="customer.shipping_address"></address-list-item>
          </v-list>

          <h2 class="headline mb-2">Other Addresses</h2>
          <v-list class="elevation-1">
            <template v-for="address in customer.addresses">
              <address-list-item label="Other Address" :model="address" v-if="address && address !== customer.billing_address && address !== customer.shipping_address"></address-list-item>
            </template>

            <!--add address-->
            <v-list-item two-line
                         @click="addAddress()"
            >
              <v-list-item-content>
                <v-list-item-title>Add Address (TODO)</v-list-item-title>
                <v-list-item-subtitle>Add address for this customer</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import debounce from 'lodash-es/debounce'
import { mapGetters } from 'vuex';
import { mapChannel } from 'vuex-jsonapi';

import AddressListItem from '../components/address-list-item'

export default {
  props: ['customerId'],
  components: { AddressListItem },
  data: () => ({
    attributes: [
      { name: 'first_name', label: 'First Name' },
      { name: 'last_name', label: 'Last Name' },
      { name: 'email', label: 'Email' },
      { name: 'phone', label: 'Phone' },
      { name: 'company', label: 'Company' },
      { name: 'wholesale_no', label: 'Wholesale Number' },
      { name: 'discount', label: 'Discount' },
      { name: 'notes', label: 'Notes' },
    ]
  }),
  beforeMount() {
    this.load();
    this.loadDebounced = debounce(this.load.bind(), 300);
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapChannel('customerCustomer', 'customer'),
  },
  methods: {
    load() {
      this.$store.dispatch('find', {
        channel: 'customerCustomer',
        type: 'customers',
        id: this.customerId,
        params: {
          include: 'billing_address,shipping_address,addresses',
        }
      })
    },
    addAddress() {
      alert('Not yet implemented');
    },
  }
}
</script>

<style scoped lang="scss">
</style>
