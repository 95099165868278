<template>
  <v-layout
    fill-height
    :pa-4="$vuetify.breakpoint.smAndUp"
    :pa-3="$vuetify.breakpoint.xsOnly"
    column
    justify-start
  >
    <v-flex xs12>

      <!--title bar-->
      <v-layout>
        <h1 class="display-1 mb-2">Inventory Stages</h1>
        <v-spacer></v-spacer>
        <v-btn outlined
               @click="create()"
        >
          Create Stage
        </v-btn>
      </v-layout>

      <v-switch v-model="filters.showInactive" label="Show Inactive"></v-switch>

      <v-list elevation="1">
        <list-channel-helper channel="stagesStages"></list-channel-helper>
        <v-list-item v-for="item in model"
                     :key="item.id"
                     two-line
        >
          <v-list-item-avatar>
            <v-avatar>
              <img v-if="item.image_url" :src="item.image_url">
              <v-icon v-else color="grey lighten-1">photo_camera</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
              <em v-if="item.default" class="grey--text">(Default)</em>
              <em v-if="item.inactive" class="grey--text">(Inactive)</em>
            </v-list-item-title>
            <v-list-item-subtitle>Priority: {{ item.priority }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon
                   :disabled="item.default"
                   @click="edit(item)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon
                   :disabled="item.default"
                   @click="toggleInactive(item)"
            >
              <v-icon>{{ item.inactive ? 'visibility_off' : 'visibility' }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-flex>

    <inventory-stage-modal-editor :model="activeStage"
                                  v-if="activeStage"
                                  @save="load"
                                  @close="activeStage = null"
    ></inventory-stage-modal-editor>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapChannel, Record } from 'vuex-jsonapi';

import ListChannelHelper from '../components/list-channel-helper'
import SelectModel from '../components/select-model'
import InventoryStageModalEditor from './inventory-stage/modal/editor'

export default {
  components: { InventoryStageModalEditor, ListChannelHelper, SelectModel },
  data: () => ({
    filters: {
      showInactive: false
    },
    activeStage: null,
  }),
  beforeMount() {
    this.load();
    this.$store.$on('didUpdateProductInventoryStages', this.load);
  },
  beforeDestroy() {
    this.$store.$off('didUpdateProductInventoryStages', this.load);
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapChannel('stagesStages'),
    params() {
      return {
        sort: '-default,priority,name',
        'filter[inactive]': this.filters.showInactive ? null : 'false'
      };
    },
  },
  watch: {
    filters: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
  methods: {
    create() {
      this.activeStage = new Record('product_inventory_stages', {
        name: '',
        priority: 10,
        inactive: false,
      });
    },
    edit(stage) {
      this.activeStage = stage.clone();
    },
    toggleInactive(stage) {
      let record = stage.clone();
      record.inactive = !record.inactive;
      this.$store.dispatch('save', { record });
    },
    load() {
      this.$store.dispatch('find', {
        channel: 'stagesStages',
        type: 'product_inventory_stages',
        params: this.params,
      });
    },
  }
}
</script>
