import moment from 'moment'
import { MomentUtils } from 'vue-tilities'

export default (options = {}) => {
  let localMoment = moment;

  if (options.localize === true) {
    localMoment.locale(window.navigator.language);
  } else if (typeof options.localize === 'string') {
    localMoment.locale(options.localize);
  }

  return new MomentUtils(localMoment);
}
