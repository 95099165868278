<template>
  <v-layout
    fill-height
    :pa-4="$vuetify.breakpoint.smAndUp"
    :pa-3="$vuetify.breakpoint.xsOnly"
    column
    justify-start
  >
    <v-flex xs12>

      <!--title bar-->
      <v-layout>
        <h1 class="display-1 mb-2">Inventory Management</h1>
        <v-spacer></v-spacer>
        <v-btn outlined
               @click="sync()"
        >
          Sync Available
        </v-btn>
      </v-layout>

      <div v-for="product in model"
           :key="product.id"
           class="mb-4"
      >
        <div class="headline mb-2">{{ product.name }}</div>
        <inventory-levels :product="product" @reload="load()"></inventory-levels>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapChannel } from 'vuex-jsonapi';
import InventoryLevels from "../components/inventory-levels";

export default {
  components: {InventoryLevels},
  data: () => ({
  }),
  beforeMount() {
    this.load();
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapChannel('productsProducts'),
    params() {
      return {
        include: 'inventory_levels,inventory_levels.stage',
        sort: 'name',
        'page[limit]': 100,
      };
    },
    counts() {
      return (this.meta || {}).counts || {};
    },
  },
  methods: {
    load() {
      this.$store.dispatch('find', {
        channel: 'productsProducts',
        type: 'products',
        params: this.params,
      })
    },
    sync() {
      this.$api
        .request('POST', 'products/sync', {})
        .then(this.load.bind(this))
        .catch(() => { this.$store.dispatch('updateSnackbar', 'There was an error syncing products') });
    },
  }
}
</script>

<style scoped lang="scss">
</style>
