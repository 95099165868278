<template>
  <v-layout align-center justify-center>
    <v-sheet elevation="10"
             :width="$vuetify.breakpoint.smAndUp ? '50%' : '100%'"
             class="pa-6 ma-4 text-center"
    >
      <p class="display-1">Welcome to Fiat Luxe <span class="primary--text">Inventory</span></p>

      <v-text-field v-model="loginData.email"
                    label="Email Address"
      ></v-text-field>

      <v-text-field type="password"
                    v-model="loginData.password"
                    label="Password"
      ></v-text-field>
      <v-btn @click="logIn()" :loading="loading" color="primary">Log in</v-btn>
    </v-sheet>
  </v-layout>
</template>

<script>
import axios from 'axios'
import { mapChannel } from 'vuex-jsonapi'

let axiosClient = axios.create();

export default {
  data: () => ({
    loading: false,
    loginData: {
      email: '',
      password: '',
    }
  }),
  computed: {
    ...mapChannel('loginUser', 'loginUser'),
  },
  watch: {
    loginUser(user) {
      if (user) this.$store.dispatch('updateCurrentUser', user);
    },
  },
  methods: {
    logIn() {
      this.loading = true;
      axiosClient
        .post('/login', this.loginData)
        .then(res => {
          this.$store.dispatch('materialize', { records: res.data.data, channel: 'loginUser' });
        })
        .catch(e => {
          this.$store.dispatch('updateSnackbar', 'Please check your login info and try again');
          this.loading = false;
        })
    }
  }
}
</script>

<style scoped lang="scss">
  h1 {
    text-align: center;
  }
</style>
