<template>
  <v-list-item three-line v-if="model">
    <v-list-item-content>
      <v-list-item-title>{{ label }}</v-list-item-title>
      <v-list-item-subtitle>{{ model.recipient }}</v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ model.address }}
        <span v-if="model.address_2"> - {{ model.address_2 }}</span>
        - {{ model.city }}, {{ model.region }} {{ model.postal_code }}
        <span v-if="model.country !== 'USA'"> - {{ model.country }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { Record } from 'vuex-jsonapi'

export default {
  props: {
    model: {
      type: Record,
      required: true,
    },
    label: {
      type: String,
      default: 'Address',
    }
  }
}
</script>

<style scoped lang="scss">
</style>
