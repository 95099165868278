import marked from 'marked'
import moment from 'moment'
import numeral from 'numeral'
import sanitizeHtml from 'sanitize-html'

marked.setOptions({
  sanitize: true,
  sanitizer: sanitizeHtml,
});

let formatUrl = name => {
  return `/images/${name}`;
};

export default {
  install(Vue) {
    Vue.filter('mailto', (record) => {
      return `mailto:${encodeURIComponent(record.full_name + '<' + record.email + '>')}`;
    });

    Vue.filter('numeral', (value, format = '0,0[.]00') => {
      return numeral(value).format(format);
    });

    Vue.filter('currency', (value, format = '$0,0.00') => {
      return numeral(value).format(format);
    });

    Vue.filter('date_diff', (value) => {
      let days = moment.max().diff(value, 'days');
      return days + (days === 1 ? ' day' : ' days');
    });

    Vue.filter('markdown', (string = '') => {
      return marked(string);
    });

    Vue.filter('profile_image', (user) => {
      return user && user.profile_image_url ? user.profile_image_url : formatUrl('no-profile-image.png');
    });

    Vue.filter('yes_no', (value = false) => {
      return value ? 'Yes' : 'No';
    });
  }
}
