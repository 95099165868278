<template>
  <v-autocomplete
    v-model="value"
    :loading="loading"
    :items="items"
    :item-text="itemText"
    :search-input.sync="query"
    cache-items
    return-object
    :prepend-inner-icon="icon"
    :no-data-text="noDataText || (loading ? 'Searching...' : null) || (query ? `No ${type} found` : `Please type to find ${type}`)"
    :label="label || $options.filters.humanize(type)"
    :clearable="clearable"
    :messages="hint"
    :error="error"
    :outline="outline"
    :filled="filled"
    :solo="solo"
    :text="text"
  ></v-autocomplete>
</template>

<script>
import debounce from 'lodash-es/debounce'
import get from 'lodash-es/get'
import { Record } from 'vuex-jsonapi'

export default {
  data: () => ({
    loading: false,
    items: [],
    query: null,
  }),
  props: {
    type: {
      type: String,
      required: true,
    },
    model: {
      type: [Record, Object],
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    itemText: {
      type: String,
      default: 'name',
    },
    noDataText: String,
    clearable: Boolean,
    hint: [String, Array],
    error: Boolean,
    params: Object,
    label: String,
    outline: Boolean,
    filled: Boolean,
    solo: Boolean,
    text: Boolean,
  },
  created() {
    // autocomplete bug: needs value in items to show initially
    if (this.value) {
      this.items.push(this.value);
    }
    this.search = debounce((query) => {
      this.loading = true;
      return this.$api.find(this.type, Object.assign({}, this.params || [], {
        'filter[search]': query
      })).then(({ data }) => {
        this.items = data;
      }, e => {
        this.$store.dispatch('updateSnackbar', `Search error: ${e.message}`);
      })
      .finally(() => {
        this.loading = false;
      });
    }, 300);
  },
  computed: {
    // Needed since v-autocomplete sets value to undefined instead of null, which results in it being ignored in serialization
    value: {
      get() {
        return this.model[this.property];
      },
      set(val) {
        this.$set(this.model, this.property, val || null);
      }
    },
    icon() {
      return {
        grades: 'people',
        locations: 'language',
        users: 'person',
      }[this.type];
    }
  },
  watch: {
    query(query) {
      query && query !== get(this.model[this.property], this.itemText) && this.search(query)
    }
  }
}
</script>
