<template>
  <v-layout class="busy-mask active">
    <v-flex xs12>
      <v-layout align-center justify-center fill-height>
        <v-progress-circular :color="color"
                             indeterminate
        ></v-progress-circular>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: 'primary',
      }
    }
  }
</script>

<style scoped lang="scss">
  .busy-mask {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
    cursor: wait;
    transition: opacity 0.3s;
    opacity: 0;

    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }
</style>