import Vue from 'vue'
import Vuex from 'vuex'
import VueJsonapi from 'vuex-jsonapi'
import axios from 'axios'
import extend from 'lodash-es/extend'
import compact from 'lodash-es/compact'
// import get from 'lodash-es/get'

const axiosClient = axios.create({
  baseURL: '/api/v1',
  headers: {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  },
  timeout: 10000,
});

Vue.use(Vuex);

let { apiClient, state, mutations, actions, getters } = VueJsonapi.install(Vue, axiosClient);

extend(state, {
  currentUser: null,
  snackbarMessage: null,
});

extend(mutations, {
  updateCurrentUser(state, user) { state.currentUser = user },
  updateSnackbarMessage(state, message) { state.snackbarMessage = message },
});

extend(actions, {
  updateCurrentUser({ commit }, user) { commit('updateCurrentUser', user) },
  updateSnackbar({ commit }, message) { commit('updateSnackbarMessage', message) },
  updateSnackbarWithErrors({ commit }, requestError) {
    let errorMessages = compact(requestError.errors().map(function(error) {
      if (+error.status !== 422) return null;
      let parts = error.detail.split(' - ');
      return [
        Vue.filter('humanize')(parts[0]),
        parts[1]
      ].join(' ');
    }));
    commit('updateSnackbarMessage', requestError.message + (errorMessages.length ? ': ' + errorMessages.join(', ') : ''));
  },
});

extend(getters, {
  currentUser (state) { return state.currentUser },
  snackbarMessage (state) { return state.snackbarMessage },
});

let store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});

// Make event listeners more accessible
store.$on = store._vm.$on.bind(store._vm);
store.$off = store._vm.$off.bind(store._vm);
store.$once = store._vm.$once.bind(store._vm);

// Attach snackbar feedback listeners
store.$on('didSaveRecord', context => {
  if (!context.successMessage) return;
  store.dispatch('updateSnackbar', typeof context.successMessage === 'string' ? context.successMessage : 'Item saved successfully');
});

store.$on('didDeleteRecord', context => {
  if (!context.successMessage) return;
  store.dispatch('updateSnackbar', typeof context.successMessage === 'string' ? context.successMessage : 'Item deleted successfully');
});

store.$on('didFindError', context => {
  if (!context.errorMessage) return;
  store.dispatch('updateSnackbarWithErrors', context.error)
});

store.$on('didSaveError', context => {
  if (!context.errorMessage) return;
  store.dispatch('updateSnackbarWithErrors', context.error)
});

// Attach pending approvals count
// axiosClient.interceptors.response.use(response => {
//   let meta = get(response, 'data.meta') || {};
//   if (typeof meta.item !== 'undefined') { store.dispatch('updateItem', meta.item); }
//   return response;
// });

// Export the store
export default store;

// Expose services for convenience
window.$store = store;
window.$http = Vue.prototype.$http = axiosClient;
window.$api = Vue.prototype.$api = apiClient;
