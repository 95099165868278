<template>
  <v-dialog v-model="model"
            width="600"
            persistent
  >
    <v-card>
      <v-layout class="pa-6">
        <div class="headline">{{ model.id ? 'Update' : 'Create' }} Stage</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-layout>

      <v-card-text>
        <v-layout column>
          <v-form @submit="e => { e.preventDefault(); login() }">

            <!--name-->
            <v-text-field v-model="model.name"
                          label="Name"
                          class="mb-2"
                          filled
            ></v-text-field>

            <!--image_url-->
            <v-text-field v-model="model.image_url"
                          label="Image URL"
                          class="mb-2"
                          filled
            ></v-text-field>

            <!--priority-->
            <v-text-field v-model="model.priority"
                          label="Priority"
                          type="number"
                          class="mb-2"
                          filled
            ></v-text-field>

            <!--inactive-->
            <v-switch v-model="model.inactive"
                      label="Inactive"
                      class="ma-0"
            ></v-switch>
          </v-form>
        </v-layout>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <model-save-button :model="model" @save="save()" @close="cancel()"></model-save-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Record } from 'vuex-jsonapi'

import ModelSaveButton from '../../../components/model-save-button'
import ModelAutocomplete from '../../../components/model-autocomplete'

export default {
  components: { ModelAutocomplete, ModelSaveButton },
  data: () => ({
  }),
  props: {
    model: {
      type: Record,
      required: true,
    },
  },
  beforeMount() {
    this.$store.$on('didUpdateProductInventoryStages', this.emitSaveAndClose);
  },
  beforeDestroy() {
    this.$store.$off('didUpdateProductInventoryStages', this.emitSaveAndClose);
  },
  methods: {
    save() {
      this.$store.dispatch('save', { record: this.model });
    },
    cancel() {
      if (!this.model.dirty() || confirm('Discard your changes?')) {
        this.$emit('close');
      }
    },
    emitSaveAndClose() {
      this.$emit('save');
      this.$emit('close');
    }
  },
}
</script>

<style scoped lang="scss">
</style>
