<template>
  <v-layout
    fill-height
    :pa-4="$vuetify.breakpoint.smAndUp"
    :pa-3="$vuetify.breakpoint.xsOnly"
    column
    justify-start
  >
    <v-flex xs12>

      <!--title bar-->
      <h1 class="display-1 mb-2">Customers</h1>

      <!--results-->
      <v-layout wrap class="mb-3">
        <v-flex xs12 md4>
          <v-text-field v-model="filters.search"
                        prepend-inner-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        :class="{ 'mr-2': $vuetify.breakpoint.mdAndUp, 'mb-4': true }"
                        clearable
                        filled
          ></v-text-field>
        </v-flex>
      </v-layout>

      <!--main-->
      <v-data-table
        :headers="headers"
        :items="customers"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.itemsPerPage"
        :server-items-length="customersMeta.record_count"
        :sort-by.sync="pagination.sortBy"
        :sort-desc.sync="pagination.sortDesc"
        :loading="customersLoading"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 100] }"
        class="elevation-1 data-table"
      >
        <template v-slot:item="props">
          <tr @click="goToCustomer(props.item)">
            <td>{{ props.item.full_name }}</td>
            <td><a :href="props.item | mailto">{{ props.item.email }}</a></td>
            <td>{{ props.item.phone }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import debounce from 'lodash-es/debounce'
import moment from 'moment'
import { mapGetters } from 'vuex';
import { mapChannel } from 'vuex-jsonapi';

import ListChannelHelper from '../components/list-channel-helper'
import SelectModel from '../components/select-model'

let year = moment().get('year');
let filters = {
  search: '',
  grades: [],
  locations: [],
};
let pagination = {
  page: 1,
  itemsPerPage: 20,
  sortBy: ['full_name'],
  sortDesc: [false],
};

export default {
  components: { ListChannelHelper, SelectModel },
  data: () => ({

    // Table Options
    filters: filters,
    pagination: pagination,
    headers: [
      { text: 'Name', value: 'full_name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone', value: 'phone' },
    ],
  }),
  beforeMount() {
    this.load();
    this.loadDebounced = debounce(this.load.bind(), 300);
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapChannel('customersCustomers', 'customers'),
    params() {
      let sortString = this.pagination.sortBy.map((col, idx) => {
        return (this.pagination.sortDesc[idx] ? '-' : '') + col;
      }).join(',');
      return {
        'filter[search]': this.filters.search || null,
        'page[limit]': this.pagination.itemsPerPage,
        'page[offset]': (this.pagination.page - 1) * this.pagination.itemsPerPage,
        sort: this.pagination.sortBy.length ? sortString : null,
      };
    },
  },
  watch: {
    'filters.search'(val) {
      if (val) {
        this.pagination.sortBy = [];
        this.pagination.sortDesc = [];
        this.pagination.page = 1;
      }
    },
    pagination: {
      handler() { this.load(); },
      deep: true,
    },
    filters: {
      handler() { this.loadDebounced() },
      deep: true,
    },
  },
  methods: {
    load() {
      this.$store.dispatch('find', {
        channel: 'customersCustomers',
        type: 'customers',
        params: this.params,
      })
    },
    goToCustomer(customer) {
      this.$router.push({ name: 'customer', params: { customerId: customer.id }  });
    },
  }
}
</script>

<style scoped lang="scss">
</style>
