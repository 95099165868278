<template>
  <v-layout>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      class="grey lighten-5"
      clipped
      app
      floating
    >
      <v-toolbar color="white"
                 v-if="$vuetify.breakpoint.mdAndDown"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="title font-weight-light">
          <span class="font-weight-light">Fiat Luxe</span>
          <span class="font-weight-medium primary--text">Inventory</span>
        </span>
      </v-toolbar>
      <v-list
        dense
        class="mt-2"
      >
        <template v-for="(item, i) in menuItems">
          <v-divider
            v-if="item.divider"
            :key="i"
            dark
            class="my-3"
          ></v-divider>
          <v-list-item
            v-else
            :key="i"
            @click=""
            :to="{ name: item.route }"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white"
               app
               fixed
               clipped-left
               class="elevation-1"
    >
      <v-toolbar-title style="width: 300px">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="title font-weight-light">
          <span class="font-weight-light">Fiat Luxe</span>
          <span class="font-weight-medium primary--text">Inventory</span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y
              bottom
              left
              v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-avatar size="40"
                    class="ml-3"
                    v-on="on"
          >
            <img :src="currentUser | profile_image">
          </v-avatar>
        </template>
        <v-list>
          <v-list-item @click="logOut()">
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-content>
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-content>
  </v-layout>
</template>

<script>
import compact from 'lodash-es/compact'
import { mapGetters } from 'vuex'

export default {
  data: () => {
    return {
      drawer: null,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    menuItems() {
      return compact([
        { icon: 'art_track', text: 'Products', route: 'products' },
        { divider: true },
        { icon: 'group_work', text: 'Inventory Management', route: 'inventory' },
        { icon: 'timelapse', text: 'Inventory Stages', route: 'inventory-stages' },
      ]);
    }
  },
  methods: {
    logOut() {
      window.location.href = '/logout';
    }
  }
}
</script>

<style scoped lang="scss">
@import './app/assets/stylesheets/_variables';

.grey--text {
  color: #666 !important;
}

.v-list__tile--active .grey--text {
  color: $primary !important;
}
</style>
