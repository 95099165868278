<template>
  <v-layout
    fill-height
    :pa-4="$vuetify.breakpoint.smAndUp"
    :pa-3="$vuetify.breakpoint.xsOnly"
    column
    justify-start
  >
    <v-flex xs12>

      <!--title bar-->
      <v-layout>
        <h1 class="display-1 mb-2">Products</h1>
        <v-spacer></v-spacer>
        <v-btn outlined
               @click="sync()"
        >
          Sync Now
        </v-btn>
      </v-layout>

      <!--main-->
      <v-data-table
        :headers="headers"
        :items="model"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.itemsPerPage"
        :server-items-length="meta.record_count"
        :sort-by.sync="pagination.sortBy"
        :sort-desc.sync="pagination.sortDesc"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 100] }"
        class="elevation-1 data-table"
      >
        <template v-slot:top>
          <div style="height: 0;">&nbsp;</div>
          <div>
            <v-text-field v-model="filters.search"
                          prepend-inner-icon="search"
                          label="Search"
                          single-line
                          hide-details
                          class="ma-2 mb-1"
                          clearable
                          solo
                          flat
            ></v-text-field>
            <div style="border-bottom: 1px solid #ddd;"></div>
          </div>
        </template>
        <template v-slot:item="props">
          <tr @click="goToProduct(props.item)">
            <td>
              <v-avatar size="32">
                <img v-if="props.item.image" :src="props.item.image">
                <v-icon v-else color="grey lighten-1">photo_camera</v-icon>
              </v-avatar>
            </td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.category | titleize }}</td>
            <td>{{ props.item.sku }}</td>
            <td class="text-right">{{ props.item.price | currency }}</td>
            <td class="text-right">{{ (counts[props.item.id] || [])[1] | numeral }}</td>
            <td class="text-right">{{ (counts[props.item.id] || [])[0] | numeral }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import debounce from 'lodash-es/debounce'
import moment from 'moment'
import { mapGetters } from 'vuex';
import { mapChannel } from 'vuex-jsonapi';

import ListChannelHelper from '../components/list-channel-helper'
import SelectModel from '../components/select-model'

let year = moment().get('year');
let filters = {
  search: '',
  grades: [],
  locations: [],
};
let pagination = {
  page: 1,
  itemsPerPage: 20,
  sortBy: ['name'],
  sortDesc: [false],
};

export default {
  components: { ListChannelHelper, SelectModel },
  data: () => ({

    // Table Options
    filters: filters,
    pagination: pagination,
    headers: [
      { text: '', value: 'full_name', sortable: false, width: 1 },
      { text: 'Name', value: 'name' },
      { text: 'Category', value: 'category' },
      { text: 'SKU', value: 'sku' },
      { text: 'Price', value: 'price', align: 'right' },
      { text: 'Total Quantity', value: 'quantity', align: 'right', sortable: false },
      { text: 'Available Quantity', value: 'quantity', align: 'right', sortable: false },
    ],
  }),
  beforeMount() {
    this.load();
    this.loadDebounced = debounce(this.load.bind(), 300);
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapChannel('productsProducts'),
    params() {
      let sortString = this.pagination.sortBy.map((col, idx) => {
        return (this.pagination.sortDesc[idx] ? '-' : '') + col;
      }).join(',');
      return {
        'filter[search]': this.filters.search || null,
        'page[limit]': this.pagination.itemsPerPage,
        'page[offset]': (this.pagination.page - 1) * this.pagination.itemsPerPage,
        sort: this.pagination.sortBy.length ? sortString : null,
      };
    },
    counts() {
      return (this.meta || {}).counts || {};
    },
  },
  watch: {
    'filters.search'(val) {
      if (val) {
        this.pagination.sortBy = [];
        this.pagination.sortDesc = [];
        this.pagination.page = 1;
      }
    },
    pagination: {
      handler() { this.load(); },
      deep: true,
    },
    filters: {
      handler() { this.loadDebounced() },
      deep: true,
    },
  },
  methods: {
    load() {
      this.$store.dispatch('find', {
        channel: 'productsProducts',
        type: 'products',
        params: this.params,
      })
    },
    goToProduct(product) {
      this.$router.push({ name: 'product', params: { productId: product.id }  });
    },
    sync() {
      this.$api
        .request('POST', 'products/sync', {})
        .then(this.load.bind(this))
        .catch(() => { this.$store.dispatch('updateSnackbar', 'There was an error syncing products') });
    },
  }
}
</script>
