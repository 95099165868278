<template>
  <v-layout class="mx-n1">
    <drop v-for="level in sortedLevels"
          :key="level.id"
          @drop="move(dragItem.stage, level.stage)"
          class="mx-1"
    >
      <drag :style="{ cursor: 'grab' }"
            @dragstart="dragItem = level"
      >
        <v-sheet elevation="1"
                 class="text-center pa-2"
                 min-width="150"
        >
          <!--title-->
          <div class="heading mb-2">{{ level.stage.name }}</div>

          <!--image-->
          <div>
            <img :src="level.stage.image_url || level.product.image || '/images/no-image.png'"
                 class="image mb-2"
                 :class="{ 'elevation-1': !level.stage.image_url }"
            >
          </div>

          <div>
            <v-btn icon @click="change(level.stage, 1)">
              <v-icon>add</v-icon>
            </v-btn>
            <span class="title">{{ level.quantity }}</span>
            <v-btn icon @click="change(level.stage, -1)">
              <v-icon>remove</v-icon>
            </v-btn>
          </div>
        </v-sheet>
      </drag>
    </drop>
  </v-layout>
</template>

<script>
import sortBy from 'lodash-es/sortBy'
import { Record } from 'vuex-jsonapi'
import { Drag, Drop } from 'vue-drag-drop'

export default {
  data: () => ({
    dragItem: null,
    inflightRecord: null,
  }),
  components: { Drag, Drop },
  props: {
    product: {
      type: Record,
      required: true,
    },
  },
  beforeMount() {
    this.$store.$on('didCreateRecord', this.emitReload);
  },
  beforeDestroy() {
    this.$store.$off('didCreateRecord', this.emitReload);
  },
  computed: {
    sortedLevels() {
      return sortBy((this.product || {}).inventory_levels || [], 'stage.priority');
    },
  },
  methods: {
    emitReload({ record }) {
      if (this.inflightRecord === record) {
        this.inflightRecord = null;
        this.$emit('reload');
      }
    },
    change(stage, factor) {
      let quantity = +prompt(`How many would you like to ${factor === 1 ? 'add' : 'remove'}?`);
      if (quantity) {
        this.inflightRecord = new Record('product_inventory_movements', {
          product: this.product,
          quantity: factor * quantity,
          stage,
        });
        this.$store.dispatch('save', { record: this.inflightRecord });
      }
    },
    move(fromStage, toStage) {
      if (fromStage === toStage) return;

      this.dragItem = null;

      let quantity = +prompt('How many would you like to move?');
      if (quantity) {
        this.$api.request('POST', `products/${this.product.id}/move`, {
          data: {
            from_stage_id: fromStage.id,
            to_stage_id: toStage.id,
            quantity,
          }
        })
        .then(this.$emit.bind(this, 'reload'))
        .catch(() => { this.$store.dispatch('updateSnackbar', 'There was an error moving this item') });
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .image {
    pointer-events: none;
    display: block;
    max-height: 100px;
    max-width: 100px;
    margin: 0 auto;
  }
</style>
